import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import TextLoop from "react-text-loop";
import { IoOpenOutline } from "react-icons/io5";

import { CallToAction } from "../../components/site";
import { Container, Wrapper, Row, Box } from "../../components/util";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import APositiveLogo from "../../images/logos/Other/APositive_Dark_Full.svg";
import BullhornLogo from "../../images/logos/Other/Bullhorn_Dark_Full.svg";
import JobAdderLogo from "../../images/logos/Other/JobAdder_Blue_Full.svg";
import EmploymentHeroLogo from "../../images/logos/Other/EmploymentHero_Full_Black.svg";
import PayHeroLogo from "../../images/logos/PayHero/PayHero_Full.svg";
import PowerBILogo from "../../images/logos/Other/PowerBI_Dark_Full.svg";
import XeroLogo from "../../images/logos/Xero/Xero_Blue_Single.svg";
import KarmlyLogo from "../../images/logos/Karmly/Karmly_Full.svg";
import OnboardedLogo from "../../images/logos/Other/Onboarded_Logo.png";

const IntegrationsList = styled(Row)`
  --item-gap: 30px;
  align-items: stretch;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 3) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const IntegrationCard = styled.div`
  border-radius: ${(props) => props.theme.styles.borderRadius};
  background-color: ${(props) => props.theme.colours.white};
  box-shadow: ${(props) => props.theme.styles.shadow};
  border: solid 1px ${(props) => props.theme.colours.greyDarken};
  transition: all ${(props) => props.theme.transitions.med};

  .logo-container {
    height: 110px;
    border-radius: 5px 5px 0 0;
    background-color: ${(props) => props.theme.colours.lightGrey};
    padding: 20px 30px;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      img {
        max-width: 180px;
        max-height: 100%;

        &.-full {
          width: 100%;
        }
      }
    }
  }

  .content-container {
    padding: 30px;

    h3 {
      color: ${(props) => props.theme.colours.dark};
      font-weight: 600;
      display: flex;
      align-items: center;

      .badge {
        margin-left: 7px;
      }
    }
  }
`;

const Integrations = (props) => {
  const integrations = [
    {
      logo: APositiveLogo,
      full: true,
      coming_soon: false,
      category: "INVOICE FINANCE",
      name: "APositive",
      description:
        "APositive offer a range of Payroll Funding, Invoice Finance & BackOffice solutions exclusively designed for the recruitment, contracting and labour hire category.",
      link: "/recruitment/integrations/apositive",
    },
    {
      logo: BullhornLogo,
      full: true,
      coming_soon: false,
      category: "APPLICANT TRACKING",
      name: "Bullhorn",
      description:
        "Bullhorn is online recruitment agency software, providing a fully integrated applicant tracking and customer relationship management system with social media recruiting tools.",
      link: "/recruitment/integrations/bullhorn",
    },
    {
      logo: JobAdderLogo,
      full: true,
      coming_soon: false,
      category: "RECRUITMENT MANAGEMENT",
      name: "JobAdder",
      description:
        "JobAdder is a global recruitment platform built for staffing agency and corporate recruiters that empowers recruiters all over the world to recruit smarter and simpler.",
      link: "/recruitment/integrations/jobadder",
    },
    // {
    //   logo: KarmlyLogo,
    //   full: true,
    //   coming_soon: false,
    //   category: "TIME TRACKING & INVOICING",
    //   name: "Karmly",
    //   description:
    //     "Karmly is a free app for independent contractors to track time & expenses, manage projects & invoice clients.",
    //   link: "https://www.karmly.io",
    // },
    {
      logo: EmploymentHeroLogo,
      full: true,
      coming_soon: false,
      category: "AUSTRALIAN PAYROLL",
      name: "Employment Hero",
      description:
        "Employment Hero is a cloud-based payroll platform for Australian businesses. Pay contractors and increase compliance through pre-built Awards and support for custom EBA’s.",
      link: "/recruitment/integrations/employment-hero",
    },
    {
      logo: OnboardedLogo,
      full: true,
      coming_soon: false,
      category: "STAFF ONBOARDING",
      name: "Onboarded",
      description:
        "Take care of everything with Onboarded, from pre-hire registration to post-placement onboarding, background checks, inductions, signed contracts, payroll information and more!",
      link: "https://onboarded.com.au/ ",
    },
    {
      logo: PayHeroLogo,
      full: true,
      coming_soon: false,
      category: "NZ PAYROLL",
      name: "PayHero",
      description:
        "PayHero is super easy, accurate and compliant online payroll software for New Zealand businesses. Easily pay contractors and temporary staff and automatically send payroll transactions to Xero.",
      link: "/recruitment/integrations/payhero",
    },
    {
      logo: PowerBILogo,
      full: false,
      coming_soon: false,
      category: "REPORTING",
      name: "Power BI",
      description:
        "Power BI is a suite of business analytics tools that deliver insights to your organization. Connect to hundreds of data sources, simplify data prep, and drive ad hoc analysis.",
      link: "https://powerbi.microsoft.com/en-us/",
    },
    // {
    //   logo: StorecoveLogo,
    //   full: false,
    //   coming_soon: false,
    //   category: "NZ E-INVOICING",
    //   name: "Storecove",
    //   description:
    //     "Send e-Invoices directly to your clients’ accounting software via a dedicated secure global network.",
    //   link: "/features/e-invoicing",
    // },
    {
      logo: XeroLogo,
      full: false,
      coming_soon: false,
      category: "ACCOUNTING & PAYROLL",
      name: "Xero",
      description:
        "Xero is easy to use online accounting software. Get a real-time view of your cashflow, reconcile in seconds and run your business on the go with the Xero mobile app.",
      link: "/recruitment/integrations/xero",
    },
  ];

  return (
    <Layout>
      <Seo
        title="Integrate with Karmly | Placement Management for Staffing Agencies"
        description="Integrate with Karmly. Better together, connect Karmly with the best category apps for a slick end-to-end recruitment software solution."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper>
          <div className="-textCenter">
            <h1>
              Integrate with your{" "}
              <TextLoop interval={3000} mask={true}>
                <span css={{ color: "#5BA079" }}>CRM</span>
                <span css={{ color: "#5BA079" }}>ATS</span>
                <span css={{ color: "#5BA079" }}>Accounting</span>
                <span css={{ color: "#5BA079" }}>Payroll</span>
              </TextLoop>
            </h1>
            <h4>
              Connect Karmly with the best category apps for a slick end-to-end
              recruitment software solution.
            </h4>
          </div>
          <IntegrationsList isWrap justify="flex-start">
            {integrations.map((item, i) => {
              return (
                <IntegrationCard key={i}>
                  <div className="logo-container">


                    {item.link.startsWith("/") ? (
                      <Link to={item.link}>
                        <img
                          className={item.full && "-full"}
                          src={item.logo}
                          alt={item.name + " Karmly Integration"}
                        />
                      </Link>
                    ) : (
                      <a href={item.link} target="_blank" rel="noreferrer">
                        <img
                          className={item.full && "-full"}
                          src={item.logo}
                          alt={item.name + " Karmly Integration"}
                        />
                      </a>
                    )}
                  </div>
                  <Box stackGap={25} className="content-container">
                    <Box stackGap={7}>
                      <h5>{item.category}</h5>
                      <h3>
                        {item.name}
                        {item.coming_soon && (
                          <div className="badge -sm -dark">Coming Soon</div>
                        )}
                      </h3>
                      <p>{item.description}</p>
                    </Box>
                    {item.link.startsWith("/") ? (
                      <Link className="link-floating" to={item.link}>
                        Find Out More
                      </Link>
                    ) : (
                      <a
                        className="link-floating"
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Find Out More <IoOpenOutline css={{ top: "2px" }} />
                      </a>
                    )}
                  </Box>
                </IntegrationCard>
              );
            })}
          </IntegrationsList>
        </Wrapper>
      </Container>
      <CallToAction />
    </Layout>
  );
};

export default Integrations;
